.form-elements-disabled {
  select,
  input,
  button,
  textarea {
    @extend .disabled;
    @extend :disabled;
    pointer-events: none;
  }
}

.form-control {
  &-short {
    max-width: 250px;
  }

  &-mid {
    max-width: 350px;
  }
}

// For more granular states, their dom usage + corresponding definitions, see:
// - https://angular.io/guide/form-validation#control-status-css-classes
// - https://getbootstrap.com/docs/5.1/forms/validation/#supported-elements
// - https://angular.io/api/forms/NgForm#native-dom-validation-ui
.form-control,
.form-select,
.form-check-input {
  // ng-touched = focused, then blurred
  // ng-pending = angular validators haven't completed yet
  &.ng-touched:not(.ng-pending) {
    // has changes + changes are valid
    //&.ng-dirty.ng-valid {
    //  @extend .is-valid; // defined by bootstrap
    //}

    // individual form field has invalid content
    &.ng-invalid:not(form) {
      @extend .is-invalid; // defined by bootstrap
    }
  }
}

lib-invalid-feedback {
  @extend .invalid-feedback;
}

.is-valid {
}

.required-input-text {
  @extend .fst-italic;
  @extend .fw-lighter;
  @extend .text-secondary;
}

/** Change default color of checked form-switch to be $success green  */
.form-switch .form-check-input:checked {
  background-color: $success;
  border-color: $success;
}

/** Add an "On"/"Off" label to form switch, opt-in, dev needs to apply .form-check-input-status-text to the input */
.form-switch .form-check-input.form-check-input-status-text {
  margin-right: 40px;

  &:after {
    content: attr(data-form-check-status-off);
    position: relative;
    top: -5px;
    left: 40px;
    opacity: 0.5;
  }
  &:checked {
    &:after {
      content: attr(data-form-check-status-on) !important;
      opacity: 1 !important;
    }
  }
}

.alert-box {
  padding: 16px;
  align-items: flex-start;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid #e06857;
  background: #f9e1dd;
}

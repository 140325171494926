/* override default bootstrap colors */
$accent: #0d6efd;
$primary: #2e1e51;
$secondary: #d3905f;
$info: #5b9ad3;
$success: #50ab9f;
$dark: #403e3d;

/*
additional colors can go in here and we'll generate classes with this color name with
the bootstrap utilities api. e.g. .border-accent, .bg-accent, etc
 */
$custom-colors: (
  "accent": $accent,
);

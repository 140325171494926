@import "~@ng-select/ng-select/themes/default.theme.css";

.ng-select-align-end {
  .ng-dropdown-panel {
    left: unset;
    right: 0;
  }
}

.ng-select-max-width {
  .ng-dropdown-panel {
    width: unset;
    min-width: 100%;
    max-width: 90vw;
  }
}

.ng-select-container {
  @extend .rounded;
}

.ng-option-label {
  @extend .small;
}

.ng-select.ng-select-opened > .ng-select-container {
  border-color: $input-focus-border-color;
  box-shadow: $input-focus-box-shadow;
}

.ng-select.ng-select-focused .ng-select-container {
  border-color: $input-focus-border-color;
}

.ng-dropdown-panel-items {
  @extend .rounded;
}

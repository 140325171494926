/* Overrides for bootstrap variables (_variables.scss) */
//$font-size-base: 0.96rem;
//$navbar-padding-y: 0;
//$border-radius: 0.3rem;
//$border-radius-sm: 0.2rem;
//$border-radius-lg: 0.32rem;
//$input-btn-padding-y: 0.2rem;
//$input-btn-padding-x: 0.43rem;
//$input-btn-padding-y-sm: 0.15rem;
//$input-btn-padding-x-sm: 0.32rem;
//$input-btn-padding-y-lg: 0.3rem;
//$input-btn-padding-x-lg: 0.6rem;
//$form-label-margin-bottom: 0.22rem;
//$nav-link-padding-y: 0.25rem;
//$nav-link-padding-x: 0.5rem;
$nav-link-color: #495057;
$nav-link-hover-color: #495057;
$min-contrast-ratio: 2;
$table-hover-bg-factor: 0.01;
$table-cell-padding-y: 0.75rem;
$table-cell-padding-y-sm: 0.5rem;
$tooltip-max-width: 350px;
$progress-bar-transition: width 0.3s ease;
$card-border-radius: 0.5rem;
$card-border-color: var(--bs-border-color);
$modal-content-border-radius: 1rem;
$modal-backdrop-bg: #f9f7f4;
$modal-backdrop-opacity: 0.9;

$btn-padding-x: 1.125rem;

$btn-border-radius-sm: 19px;
$btn-border-radius: 23px;
$btn-border-radius-lg: 28px;

$btn-focus-width: 0.125em;
$alert-border-width: 2px;

$code-color: black;
$carousel-indicator-active-bg: black;
$input-placeholder-color: var(--bs-border-color);

$enable-smooth-scroll: false;

@import "styles/colors";
@import "projects/library/src/styles";
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import "styles/custom-tour-styles.scss";

:root {
  --bs-body-font-family: "Lato", sans-serif;
}

body {
  background-color: #f9f7f4;
}

h1,
h2 {
  color: var(--bs-primary);
}

.lib-pending-request-loader {
  .progress-bar {
    @extend .bg-accent !optional;
  }
}

nav.primary-nav {
  // todo: refactor our nav headers to use bootstrap to avoid this madness
  background-color: #34215a;

  .nav.nav-strip {
    .nav-item {
      .nav-link {
        color: white;
        font-weight: 500;
        border-bottom: 2px solid transparent;

        &.active {
          font-weight: 700;
          border-bottom-color: var(--bs-secondary);
        }

        &:hover {
          border-bottom-color: var(--bs-secondary);
        }
      }
    }
  }
}

.modal-dialog {
  border-radius: $modal-content-border-radius; // this is a bug; it's supposed to be taken care of in bootstrap/scss/_modal.scss
}

code {
  background-color: #eee;
  border-radius: 3px;
  border: 1px solid #ccc;
  font-family: courier, monospace;
  padding: 0 3px;
}

// Apex chart customizations
.apexcharts-tooltip-title {
  display: none;
}

.apexcharts-toolbar {
  left: 0;
  top: 38px !important;
  right: unset !important;

  @extend .d-none;
  @extend .d-sm-flex;
}

.apexcharts-selected {
  @extend .text-primary;
}

.apexcharts-menu-icon,
.apexcharts-reset-icon,
.apexcharts-zoom-icon {
  transform: none !important;
}

.apexcharts-menu-icon,
.apexcharts-pan-icon,
.apexcharts-reset-icon,
.apexcharts-selection-icon,
.apexcharts-toolbar-custom-icon,
.apexcharts-zoom-icon,
.apexcharts-zoomin-icon,
.apexcharts-zoomout-icon {
  width: auto !important;
  height: auto !important;
}

.bg-org-signup {
  background-image: url(/assets/signup.png), linear-gradient(180deg, rgba(52, 33, 90, 0) 0%, #34215a 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.carousel-indicators > button {
  border-radius: 50%;
  height: 0.65rem !important;
  width: 0.65rem !important;
}

.form-control:focus::placeholder {
  color: var(--bs-gray-500)
}

.text-muted {
  color: var(--bs-gray-600) !important;
}

@import "@sjmc11/tourguidejs/src/scss/tour";
@import "bootstrap/scss/bootstrap";

// Overrides default tourguidejs styles to comply with Cadence branding.

.tg-dialog {
  min-width: 360px;
  border-radius: 0.5rem;

  .tg-dialog-header {
    padding: 2rem 2rem 0 2rem;
    left: 1rem;

    .tg-dialog-title {
      font-size: 1.25rem;
      font-weight: 500 !important;
      color: var(--bs-heading-color) !important;
      line-height: 1.2 !important;
      margin-top: 0 !important;
      margin-bottom: 0.5rem !important;
    }

    .tg-dialog-close-btn {
      @extend .btn-close;

      padding: 0.75rem 0 0.75rem 0.75rem !important;
      margin-bottom: 0.5rem !important;

      svg {
        display: none !important;
      }
    }
  }

  .tg-dialog-body {
    font-size: var(--bs-body-font-size) !important;
    padding: 0 2rem !important;
    margin-bottom: 2rem !important;
  }

  .tg-dialog-footer {
    button.tg-dialog-btn {
      @extend .btn;
      @extend .btn-primary;

      font-size: var(--bs-btn-font-size) !important;
      color: var(--bs-btn-color) !important;
      padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x) !important;
    }
  }
}

// [bootstrap defaults]

$carousel-transition-duration: 300ms;
$box-shadow-inset: inset 0 2px 10px rgb(0 0 0 / 20%);
$hr-height: 0.5px;

@import "styles/utilities";
@import "bootstrap/scss/bootstrap";
@import "styles/layout";
@import "styles/form";
@import "styles/ng-select";

/* Remove y padding for btn-links in tables */
.table td .btn-link {
  @extend .py-0;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration-color: $gray-500;
  text-underline-offset: 2px;
}

.abbr {
  @extend abbr;
}

nav.primary-nav {
  background-color: $gray-100;
}

.dropdown-menu {
  @extend .shadow;

  max-height: 30vh;
  overflow: auto;
}

.btn-light {
  border-color: var(--bs-gray-300) !important;

  .active,
  &:hover {
    border-color: var(--bs-gray-500) !important;
  }
}

@media (min-width: 992px) {
  .modal-xl {
    max-width: 1024px !important;
  }
}

// [utilities]

.text-prewrap {
  white-space: pre-wrap !important;
}

.transition-all {
  transition: all 200ms;
}

// [components]

.nav.nav-strip {
  .nav-item {
    .nav-link {
      border-bottom: 2px solid transparent;

      &.active {
        border-bottom-color: var(--bs-primary);
      }

      &:hover {
        border-bottom-color: var(--bs-gray-600);
      }
    }
  }
}

.lib-pending-request-loader {
  .progress-bar {
    @extend .bg-primary;
    @extend .opacity-50;
  }
}

.alert-container {
  position: fixed;
  top: 0;
  z-index: $zindex-tooltip;
  display: flex;
  justify-content: center;
  width: 100%;
  pointer-events: none;

  .alert {
    pointer-events: all;
    width: 300px;

    .btn-close {
      padding: 0.75rem !important;
    }
  }
}

.z-index-sticky {
  z-index: $zindex-sticky;
}

.html-output-card {
  @extend .card;
  @extend .p-2;
  @extend .max-w-600;
  border-left-width: 3px !important;
  border-left-color: $gray-500 !important;

  .html-output-card-label {
    @extend .text-light;
    @extend .px-1;
    background: $gray-600;
    font-size: 8px;
    position: absolute;
    top: -13px;
    right: $border-radius * 2;
    border-radius: $border-radius $border-radius 0 0;
  }
}

// this is upside-down version of .shadow from bootstrap
.shadow-top {
  box-shadow: 0.5rem 0 1rem rgba($black, 0.15);
}
.shadow-top-sm {
  box-shadow: 0.125rem 0 0.25rem rgba($black, 0.075);
}

.active-filter {
  @extend .border-primary;
  outline: 1px $primary solid;
}

.striped > * {
  background-color: $white;
}
.striped > *:nth-of-type(odd) {
  background-color: $body-bg !important;
}

// todo: deprecate once we've revamped our error page on enduser app
.wrapper-background {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
  height: 290px;
  z-index: 9;

  .mountains-image {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .grass-image {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .trees-image {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 3;
  }

  .clouds-image {
    position: absolute;
    left: 17%;
    bottom: 16vh;
    right: 0;
    height: 60px;
    z-index: 2;
  }
}

.is-test-indicator {
  border-top: solid 1px #f26d61;
}

.is-test-indicator-label {
  background: #f26d61;
  font-size: 0.65rem;
  font-weight: bold;
  text-transform: uppercase;
}

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/variables-dark";
@import "node_modules/bootstrap/scss/maps";

/*
 add custom colors to boostrap theme colors so bootstrap generates
 .text-accent, .bg-accent, .btn-accent, .border-accent etc.
 */
$custom-colors: () !default;
$theme-colors: map-merge-multiple($theme-colors, $custom-colors);
$utilities-text-colors: map-merge-multiple($utilities-text-colors, $custom-colors);
$utilities-bg-colors: map-merge-multiple($utilities-bg-colors, $custom-colors);
$utilities-colors: map-merge-multiple($utilities-colors, $custom-colors);

@import "bootstrap/scss/utilities";

// generate max-w-* css classes for max-width property
$utilities: map-merge(
  $utilities,
  (
    "max-width-px": (
      property: max-width,
      class: max-w,
      values: (
        50: 50px,
        75: 75px,
        100: 100px,
        150: 150px,
        200: 200px,
        250: 250px,
        300: 300px,
        350: 350px,
        400: 400px,
        450: 450px,
        500: 500px,
        550: 550px,
        600: 600px,
        650: 650px,
        700: 700px,
        800: 800px,
        900: 900px,
        1000: 1000px,
        1100: 1100px,
        1200: 1200px,
      ),
    ),
  )
);

$utilities: map-merge(
  $utilities,
  (
    "v": (
      property: visibility,
      class: v,
      responsive: true,
      values: (
        hidden: hidden,
        visible: visible,
      ),
    ),
  )
);

$utilities: map-merge(
  $utilities,
  (
    "width": (
      property: width,
      class: w,
      responsive: true,
      values: (
        25: 25%,
        50: 50%,
        75: 75%,
        100: 100%,
        auto: auto,
      ),
    ),
  )
);
